<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('gong-dan') }}</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mx-auto mb-3" >
        <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" data-toggle="modal" data-target="#ticket_modal" @click="add">
          <span class="size-70px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
            <i class="las la-plus la-3x text-white"></i>
          </span>
          <div class="fs-20 text-primary">{{ $t('chuang-jian-gong-dan') }}</div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('gong-dan-0') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0" :class="tableData.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th data-breakpoints="lg">{{ $t('gong-dan-hao') }}</th>
              <th data-breakpoints="lg">{{ $t('fa-song-ri-qi') }}</th>
              <th>{{ $t('zhu-ti') }}</th>
              <th>{{ $t('zhuang-tai') }}</th>
              <th data-breakpoints="lg">{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>#{{ item.ID }}</td>
              <td>{{ item.CreatedAt }}</td>
              <td>{{ item.Subject }}</td>
              <td>
                <span class="badge badge-inline badge-danger" v-if="item.Status == 2">{{ $t('chu-li-zhong') }}</span>
                <span class="badge badge-inline badge-secondary" v-if="item.Status == 1">{{ $t('yi-da-kai') }}</span>
                <span class="badge badge-inline badge-success" v-if="item.Status == 3">{{ $t('yi-jie-jue') }}</span>
              </td>
              <td>
                <a class="btn btn-styled btn-link py-1 px-0 icon-anim text-underline--none" @click="toDetail(item)">
                  {{ $t('cha-kan') }} <i class="la la-angle-right text-sm"></i>
                </a>
              </td>
            </tr>
             <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="5">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page"></pageBar>
        </div>
      </div>
    </div>

    <Popup v-model="showPopup">
      <div class="popup-box " id="wallet_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('chuang-jian-gong-dan-0') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zhu-ti-0') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <input type="text"  class="form-control mb-3" name="amount" :placeholder="$t('zhu-ti-1')" required>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('xiang-xi-shuo-ming') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                    <textarea type="text" class="form-control mb-3 height-auto" rows="3" name="details" :placeholder="$t('shu-ru-ni-de-miao-shu')" data-buttons="bold,underline,italic,|,ul,ol,|,paragraph,|,undo,redo" required></textarea>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zhao-pian') }} </label>
                  </div>
                  <div class="col-md-9">
                    <div class="mb-3">
                      <div class="input-group" data-toggle="aizuploader" data-type="image" data-multiple="true">
                        <div class="input-group-prepend">
                          <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                        </div>
                        <div class="form-control file-amount" @click="addFile">{{ $t('xuan-ze-wen-jian') }}</div>
                        <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
                      </div>
                      <previewBar :fileList="fileList" @remove="removeFile"></previewBar>
                    </div>
                  </div>
                </div>
                <div class="mt-4 text-right">
                  <button type="button" class="btn btn-secondary" @click="cancel">{{ $t('qu-xiao') }}</button>
                  <button type="submit" class="btn btn-primary ml-2">{{ $t('que-ding') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import previewBar from '@/components/preview'
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      form: {
        deliverStatus: '',
        payStatus: ''
      },
      fileList: [],
      statusList1: [],
      statusList2: [],
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  methods: {
    toDetail(data) {
      this.$router.push({
        name: 'sellerTicketDetail'
      })
    },
    cancel() {
      this.showPopup = false
    },
    add() {
      this.showPopup = true
    },
    addFile() {
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      this.fileList.push(file)
    },
    removeFile(i) {
      this.fileList.splice(i, 1)
    }
  }
}
</script>